<ng-template #note>
  @if (user.campaignExists) {
    @if (user.lastExchangeDate === null || getDaysSinceLastExchange() >= nbDaysBetweenExchange) {
      @if (user.nbAutoObservation === 0 && user.nbObservation === 0) {
        <span class="text-secondary">
          {{ "team-member.En attente d'observations" | translate }}
          <i class="fa-light fa-spinner ms-2"></i>
        </span>
      }
      @if (user.nbAutoObservation > 0 && user.nbObservation === 0) {
        <span class="text-primary">
          {{ "team-member.En attente d'observation" | translate }}
          <i class="fa-light fa-spinner ms-2"></i>
        </span>
      }
      @if (user.nbAutoObservation === 0 && user.nbObservation > 0) {
        <span class="text-tertiary">
          {{ "team-member.En attente d'auto-observation" | translate }}
          <i class="fa-light fa-spinner ms-2"></i>
        </span>
      }
      @if (user.nbAutoObservation > 0 && user.nbObservation > 0) {
        <span class="text-success">
          {{ 'team-member.Prêt à échanger' | translate }}
          <i class="fa-light fa-check ms-2"></i>
        </span>
      }
    }
  } @else {
    {{ 'team-member.Pas de campagne en cours' | translate }}
  }
</ng-template>

<div
  class="bg-white border rounded-3 p-2 d-flex h-100"
  [class.bg-light-30p-hover]="withHover()"
  [style.border-color]="getCardStyle()"
  [style.outline]="getOutlineStyle()"
  >
  <div class="row g-1 w-100">
    @if (clientEnv.is(clientEnv.SKILLSUP)) {
      <div class="col-auto d-flex align-items-center align-icon">
        <hiji-user-avatar
          style="width: 60px; height: 60px"
          [idUser]="user.idUser"
          [avatarExtension]="user.avatarExtension"
          [defaultBlueAvatar]="true"
          [rounded]="false"
        ></hiji-user-avatar>
      </div>
    } @else {
      <div class="col-auto d-flex align-items-center align-icon justify-content-center">
        <hiji-user-avatar
          style="width: 60px; height: 60px"
          [idUser]="user.idUser"
          [avatarExtension]="user.avatarExtension"
          [defaultBlueAvatar]="true"
        ></hiji-user-avatar>
      </div>
    }

    <div class="col my-fs-1 my-fs-lg-2 d-flex flex-column justify-content-center">
      <div class="row">
        <div class="col-auto my-fs-2 my-fs-xxl-3">
          <b [style.color]="getCardStyle()">{{ user.firstName }} {{ user.lastName }}</b>
        </div>
      </div>
      @if (modules.frontConfiguration.displayFunctionFront) {
        <div class="row">
          <div class="col">
            {{ user.functionName | uppercase }}
          </div>
        </div>
      }

      @if (showCallToAction() && user.isPDIAvailable) {
        <div class="row">
          <div class="col text-warning">
            {{ "team-member.En attente d'une observation PDI" | translate }}
            <i class="fa-light fa-check ms-2"></i>
          </div>
        </div>
      }
      @if (showCallToAction() && modules.moduleEMA.enabled && user.isTargetedByEMA) {
        <div class="row">
          @if (me && user.nbObsOrAutoObsEMA < user.nbObsToDoEMA && user.nextObsOrAutoObsHintDateEMA !== null) {
            <div class="col hiji-text-auto-observation">
              <span
                class="hiji-text-auto-observation"
                [class.text-success]="DateUtils.isToday(user.nextObsOrAutoObsHintDateEMA)"
                [class.text-danger]="DateUtils.isPast(user.nextObsOrAutoObsHintDateEMA)"
                >
                {{ 'team-member.Prochaine auto-observation le \{\{date\}\}' | translate: { date: user.nextObsOrAutoObsHintDateEMA | localizedDate } }}
              </span>
              @if (user.nextEMADate !== null) {
                <span>
                  ({{ 'team-member.Rendez-vous le \{\{date\}\}' | translate: { date: user.nextEMADate | localizedDate } }})
                </span>
              }
            </div>
          }
          @if (!me && user.nbObsOrAutoObsEMA < 1 && user.nextObsOrAutoObsHintDateEMA !== null) {
            <div class="col">
              <span
                class="col hiji-text-manager"
                [class.text-success]="DateUtils.isToday(user.nextObsOrAutoObsHintDateEMA)"
                [class.text-danger]="DateUtils.isPast(user.nextObsOrAutoObsHintDateEMA)"
                >
                {{ 'team-member.Prochaine observation le \{\{date\}\}' | translate: { date: user.nextObsOrAutoObsHintDateEMA | localizedDate } }}
              </span>
              @if (user.nextEMADate !== null) {
                <span>
                  ({{ 'team-member.Rendez-vous le \{\{date\}\}' | translate: { date: user.nextEMADate | localizedDate } }})
                </span>
              }
            </div>
          }
          @if (user.nextEMADate !== null && ((!me && user.nbObsOrAutoObsEMA >= 1) || (me && user.nbObsOrAutoObsEMA >= user.nbObsToDoEMA))) {
            <div
              class="col"
              [class.text-success]="DateUtils.isToday(user.nextEMADate)"
              [class.text-danger]="DateUtils.isPast(user.nextEMADate)"
              [class.text-muted]="!DateUtils.isPast(user.nextEMADate)"
              >
              {{ 'team-member.Rendez-vous le \{\{date\}\}' | translate: { date: user.nextEMADate | localizedDate } }}
            </div>
          }
          @if (user.nextEMADate === null && (user.isWithPlanNextStep || user.nbObsOrAutoObsEMA > 0)) {
            <div class="col text-warning">
              {{ 'team-member.Rendez-vous à planifier' | translate }}
            </div>
          }
          @if (user.nextEMADate === null && !user.isWithPlanNextStep && user.nbObsOrAutoObsEMA === 0) {
            <div class="col text-warning">
              {{ me ? ('team-member.Auto-observation à réaliser' | translate) : ('team-member.Observation à réaliser' | translate) }}
            </div>
          }
        </div>
      }
      @if (showCallToAction() && user.lastExchangeDate !== null && getDaysSinceLastExchange() < nbDaysBetweenExchange) {
        <div class="row" id="next-exchange">
          <div class="col text-success">
            <i class="fa-light fa-check me-1"></i>
            {{ 'user-card.Prochain échange dans \{\{nbDays\}\} jours' | translate: { nbDays: nbDaysBetweenExchange - getDaysSinceLastExchange() } }}
          </div>
        </div>
      }
      @if ((showCounters || showDates) && modules.module180.enabled) {
        <div class="row g-1">
          @if (showCounters) {
            <div class="col-auto d-flex align-items-center">
              <span class="badge hiji-bg-auto-observation rounded-circle">{{ user.nbAutoObservation }}</span>
              <span class="hiji-text-auto-observation ms-1">
                {{ 'global.Auto-observation' | translate }}
              </span>
              @if (showDates && user.lastAutoObservationDate !== null) {
                <span class="hiji-text-auto-observation ms-1">
                  {{ user.nbAutoObservation === 0 ? ('team-member.Dernière auto-observation' | translate) + ' ' : '' }}
                  {{ user.lastAutoObservationDate | amLocale: translate.currentLang | amTimeAgo }}
                </span>
              }
            </div>
          }
        </div>
      }

      @if ((showCounters || showDates) && modules.module180.enabled) {
        <div class="row g-1">
          @if (showCounters) {
            <div class="col-auto d-flex align-items-center">
              <span class="badge hiji-bg-manager rounded-circle">{{ user.nbObservation }}</span>
              <span class="hiji-text-manager ms-1">{{ 'global.Observation' | translate }}</span>
              @if (showDates && user.lastObservationDate !== null) {
                <span class="hiji-text-manager ms-1">
                  {{ user.nbObservation === 0 ? ('team-member.Dernière observation' | translate) + ' ' : ''
                  }}{{ user.lastObservationDate | amLocale: translate.currentLang | amTimeAgo }}
                </span>
              }
            </div>
          }
        </div>
      }

      @if (showDates && user.lastExchangeDate != null && modules.module180.enabled) {
        <div class="row">
          <div class="col">{{ 'team-member.Dernier échange' | translate }} {{ user.lastExchangeDate | amLocale: translate.currentLang | amTimeAgo }}</div>
        </div>
      }
      @if (showCallToAction() && modules.module180.enabled) {
        <div class="row">
          <div class="col">
            <ng-container *ngTemplateOutlet="note"></ng-container>
          </div>
        </div>
      }
    </div>
    @if (getCardStyle() !== null) {
      <div class="col-auto d-flex flex-column justify-content-center" [style.color]="getCardStyle()">
        @if (user.rank === 1) {
          <i class="fa-light fa-2x fa-person-simple"></i>
        }
        @if (user.rank === 2) {
          <i class="fa-light fa-2x fa-person-running"></i>
        }
        @if (user.rank === 3) {
          <i class="fa-light fa-2x fa-person-running-fast"></i>
        }
        <!--<div class="rounded-circle" style="width: 40px; height: 40px" [style.background-color]="getCardStyle()"></div>-->
      </div>
    }
  </div>
</div>
